/**
 * A DefaultDict implement and a MapSet implement, these two are
 * very helpful for groupping data. MapSet just is a dummy specific
 * DefaultDict.
 * @Author liuhua@xiaoyezi.com
 */

/**
 * One implement based on object. It support string and number as
 * indexing-assignment. This one can be used like:
 * @example 
 * 	>>> dd = defaultDict(Map)
 * 	>>> dd[1].set(2, 3)
 * 	>>> dd['2'].set(3, 4)
 *  >>> dd1 = defaultDict(Array<any>)
 *  >>> dd1['a'].push(6)
 */

interface IDefaultConstructor<V> {
	new (): V
}
export interface IDefaultDict<V> {
	[index: string]: V,
	[index: number]: V,
}
export function defaultDict<V>(defaultFactory: IDefaultConstructor<V>): IDefaultDict<V> {
	const handler = {
		get(target: any, prop: any): V {
			if (prop in target) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				return target[prop]
			} else {
				const value: V = new defaultFactory()
				target[prop] = value
				return value
			}
		}
	}
	return new Proxy(Object.create(null), handler)
}

/**
 * Another one implemt based on map. It do not support indexing-assignment,
 * but it support take any type as a group key. This one can be used like:
 * @example
 * 		>>> dd = new DefaultDict(Map)
 *  	>>> dd.get(1).set(2, 2)
 *  	>>> dd.get('2').set(3, 3)
 *  	>>> dd.get([5]).set(6, 7)
 *  	>>> dd1 = new DefaultDict(Array)
 *  	>>> dd1[7].push(8)
 */
export class  DefaultDict<K, V> extends Map<K, V>{
	private defaultConstructor: IDefaultConstructor<V>

	constructor(dc: IDefaultConstructor<V>){
		super()
		this.defaultConstructor = dc
	}

	public get(key: K): V {
		if (this.has(key)) {
			return super.get(key) as V
		} else {
			const v = new this.defaultConstructor()
			this.set(key, v)
			return v
		}
	}
}

/*
 * Neither one of bellow is as good as below one. why?
 */
export class SetMap<K, V> {

	private map = new Map<K, Set<V>>();

	add(key: K, ...value: Array<V>): void {
		let values = this.map.get(key);

		if (!values) {
			values = new Set<V>();
			this.map.set(key, values);
		}

		for (const v of value) {
			values.add(v)
		}
	}

	// why they didnt do this but i did?
	get(key: K): Set<V> {
		let values = this.map.get(key); 
		if (!values) {
			values = new Set<V>();
			this.map.set(key, values);
		}
		return values
	}

	delete(key: K, value: V): void {
		const values = this.map.get(key);

		if (!values) {
			return;
		}

		values.delete(value);

		if (values.size === 0) {
			this.map.delete(key);
		}
	}

	forEach(key: K, fn: (value: V) => void): void {
		const values = this.map.get(key);

		if (!values) {
			return;
		}

		values.forEach(fn);
	}
}
