/**
 * 回放的本质是一种音画同步
 * 
 * TODO
 * If needed. make it possible that load data from old schemas.
 * so we can sync notes from userPlay, old results, new results
 * yielded by ocean, raw midi notes from score.
 * */
import EventEmitter from 'event-emitter-es6'

import { LinkedList, Node } from '../../libs/ddl'
import { DefaultDict, defaultDict, SetMap } from '../../libs/defaultdict'
import { Range, IRange } from '../../libs/range'


export enum AlignMode {
  NORMAL,
  ALORITHM
}

export class OceanAudioNoteAligner extends EventEmitter {
  private syncData: any

  private _speed = 1
  private _playable = true
  private _mode: AlignMode = AlignMode.NORMAL

  private currentNode: Node<number> = Node.Undefined
  private range: IRange | null = null
  private headMargin: IRange | null = null

  private playSequence: LinkedList<number> | null = null
  private notesSet = new SetMap<number, any>()
  protected stampEventSets = new DefaultDict<number, Map<string, Array<any>>>(Map)

  constructor(syncData: any, mode: AlignMode) {
    super()
    this.syncData = syncData
    this._mode = mode || AlignMode.NORMAL
    this._mode = AlignMode.NORMAL
    // this.loadData()
    this.buildUserScopePlayMidiFromPlay(syncData.realtime_play)
  }

  set speed(s: number) {
    if (s <= 0) {
      throw Error(`Got a invalid speed value ${s}`)
    }
    this.speed = s
  }
  get speed(): number { return this.speed }
  set mode(s: AlignMode) {
    if (this._mode === s) { return }
    this._mode = s
    this.loadData()
  }
  get mode(): AlignMode { return this._mode }
  get playable(): boolean { return this._playable }

  /**
   * Seek user scope playing-midi timestamp by stamp.
   * @param stamp the target timestamp
   * @returns -1 means not found else the seeked value will be return
   */
  public seek(stamp: number) {
    if (stamp === 0) {
      this.reset()
      return this.playSequence?.head.element
    }
    return this.seekByStamp(stamp)
  }
  /**
   * Seek user scope playing-midi timestamp by scoreNoteId.
   * @returns -1 means not found.
   */
  public seekByNoteId(noteId: number): number {
    let head = this.playSequence?.head as Node<number>
    while (head.isNotNull()) {
      for (const n of this.notesSet.get(head.element)) {
        if (n.scoreNote.id == noteId) {
          this._reset(null, head)
          return head.element
        }
      }
      head = head.next
    }
    return -1
  }
  /**
   * Seek user scope playing-midi timestamp by scoreNoteId and tick.
   * @param note A tuple in which first is noteId and second is tick
   * @returns -1 means not found. 
   */
  public seekByNoteIdTick(note: [number, number]) {
    const [noteId, noteTick] = note;
    const head = this.playSequence?.head as Node<number>
    while (head.isNotNull()) {
      for (const n of this.notesSet.get(head.element)) {
        if (n.scoreNote.id == noteId && n.scoreNoteTick == noteTick) {
          this._reset(null, head)
          return head.element
        }
      }
    }
    return -1
  }
  /**
   * Reset user scope playing-midi to zero position.
   */
  public reset() {
    this._reset(null, this.playSequence?.head)
  }
  private _reset(range: IRange | null = null, node: Node<number> = Node.Undefined) {
    this.range = range
    this.currentNode = node
  }
  private seekByStamp(stamp: number): number {
    const head = this.playSequence?.head as Node<number>
    if (!head.isNotNull()) {
      console.error('Play list is empty')
      return -1
    }
    const minStamp = head.element
    if (stamp < minStamp) {
      this._reset(null, head)
      return minStamp
    }
    let current = head
    while (current.isNotNull()) {
      const currentStamp = current.element
      const next = current.next
      const nextStamp = next.isNotNull() ? next.element : Infinity
      if (stamp >= currentStamp && stamp < nextStamp) {
        this._reset(null, current)
        return current.element
      } else {
        current = current.next
      }
    }
    console.error('Play seek error')
    return -1
  }

  private checkRange(range: IRange) {
    if (!range.done) {
      const notes = new Map<string, Array<any>>()
      notes.set('updateCursorAndColor', range.value)

      if (this._mode === AlignMode.ALORITHM) {
        const eventsOnStamp = this.stampEventSets.get(range.start)
        notes.set('resetColor', eventsOnStamp?.get('discarded') || [])
        notes.set('setColor', eventsOnStamp?.get('revised') || [])
      }
      for (const note of range.value) {
        this.emit('noteOn', note)
      }
      range.done = true
    }
  }

  /**
   * This is the motivator.
   */
  public onProgress(progress: number) {
    if (this.range === null) {
      this.windowSlider()
    }
    // console.log('>>>', progress, this.range)
    if (Range.inRange(progress, this.range as IRange)) {
      this.checkRange(this.range as IRange)
    } else if (Range.inRange(progress, this.headMargin as IRange)) {
      return
    } else {
      // It still has chances to lose some notes to render. Especially when
      // notes have multi voice in same tick, but have no same userTime.And
      // the more quick the speed is the more this problem may occurs.  The
      // soultion is group the notes that in same tick, use a merged IRange
      // start with min userTime and end with max userTime in the same tick.
      // I have no idea about this soultion works or not. Try it out we have
      // lots of miss-rendered notes bugs.
      this.windowSlider()
      this.checkRange(this.range as IRange)
    }
  }
  /**
   * Load data from results.
   */
  private loadData(): void {
    const { realtime_results } = this.syncData
    if (!realtime_results) {throw Error('未找到播放内容')}
    const resultsLength = (<Array<any>>realtime_results).length
    const defaultStampEvntSet = this.stampEventSets
    let revisedResult: any[] = []
    let unrevisedResult: any[] = []

    function coincided(nextResult: Array<any>): [number, number] {
      const nextResultLen = nextResult.length
      const rawResultLen = revisedResult.length
      if (nextResultLen === 0) {
        return [rawResultLen, 0]
      }

      let rawResultIdx = revisedResult.length - 1
      let coincidedNum = 0
      while (rawResultIdx >= 0) {
        if (revisedResult[rawResultIdx].userTime < nextResult[0].userTime) {
          break
        }
        rawResultIdx--
        coincidedNum = coincidedNum + 1
      }
      // const coincidedNum = rawResultLen - rawResultIdx
      return [rawResultIdx, coincidedNum]
    }

    for (let idx = 0; idx <= resultsLength - 1; idx++) {
      const currentResult = []
      for (const n of realtime_results[idx]) {
        if (n.scoreNote) { currentResult.push(n) }
      }

      if (currentResult.length == 0) continue
      if (revisedResult.length === 0) {
        revisedResult = revisedResult.concat(currentResult)
        unrevisedResult = unrevisedResult.concat(currentResult)
      } else {
        const [coincidedIdx, b] = coincided(currentResult)
        const reserved = revisedResult.slice(0, coincidedIdx + 1)
        const discarded = revisedResult.slice(coincidedIdx + 1)
        const revised = (currentResult as Array<any>).slice(0, b)
        const newcoming = (currentResult as Array<any>).slice(b)
        if (coincidedIdx === 0) {
          revisedResult = currentResult
        } else {
          revisedResult = reserved.concat(currentResult)
        }

        // const stamp = unrevisedResult[unrevisedResult.length - 1]?.userTime
        const stamp = newcoming[0]?.userTime

        if (stamp) {
          defaultStampEvntSet.get(stamp).set('discarded', discarded)
          defaultStampEvntSet.get(stamp).set('revised', revised)
          unrevisedResult = unrevisedResult.concat(newcoming)
        }
      }
    }
    OceanAudioNoteAligner.inspectNotes(...revisedResult)
    if (this.mode === AlignMode.ALORITHM) {
      this.buildUserScopePlayMidi(unrevisedResult)
      // OceanAudioNoteAligner.inspectNotes(...unrevisedResult)
    } else {
      this.buildUserScopePlayMidi(revisedResult)
    }
  }
  /**
   * Build user scope playing midi.
   */
  private buildUserScopePlayMidi(results: Array<any>) {
    // eslint-disable-next-line prefer-const
    let { notations } = this.syncData
    const notatedNotes = this.makeAbnormalNotes(notations)

    // Acturally we do not need a sort. caz the data itsself is sorted
    // For safelly we do this, it should be very quick.
    results = results.sort((a: any, b: any) => { return a.userTime - b.userTime })
    const dedup = new Set()
    const ddl = new LinkedList<number>()
    for (const note of results) {
      this.notesSet.add(note.userTime, note)
      if (!dedup.has(note.userTime)) {
        ddl.push(note.userTime)
        dedup.add(note.userTime)
      }
    }
    this.playSequence = ddl
    this.currentNode = this.playSequence.head
    this.headMargin = { start: 0, end: ddl.head.element }
  }

  /**
 * Build user scope playing midi.
 */
  private buildUserScopePlayMidiFromPlay(results: Array<any>) {
    // eslint-disable-next-line prefer-const
    let { notations } = this.syncData
    const notatedNotes = this.makeAbnormalNotes(notations)
    if (!results) {
      console.log(`Data ERR results=${results}`)
      throw Error('未找到播放内容')
    }
    // Acturally we do not need a sort. caz the data itsself is sorted
    // For safelly we do this, it should be very quick.
    results = results.sort((a: any, b: any) => { return a.userTime - b.userTime })
    const dedup = new Set()
    const ddl = new LinkedList<number>()
    for (const note of results) {
      this.notesSet.add(note.time, note)
      if (!dedup.has(note.time)) {
        ddl.push(note.time)
        dedup.add(note.time)
      }
    }
    this.playSequence = ddl
    this.currentNode = this.playSequence.head
    this.headMargin = { start: 0, end: ddl.head.element }
  }

  /**
 * Build user scope playing midi.
 */
  private grouplyBuildUserScopePlayMidi(results: Array<any>) {
    const { notations } = this.syncData
    const notatedNotes = this.makeAbnormalNotes(notations)

    // Acturally we do not need a sort. caz the data itsself is sorted
    // For safelly we do this, it should be very quick.
    results = results.sort((a: any, b: any) => { return a.userTime - b.userTime })

    const dedup = new Set()
    const ddl = new LinkedList<number>()
    const groupByTick = new DefaultDict<number, Array<any>>(Array)
    const minUserTime = function (notes: Array<any>): number {
      let min = Infinity
      for (const note of notes) {
        if (note.userTime < min) {
          min = note.userTime
        }
      }
      return min
    }

    let preTick = -1
    let idx = 1
    for (const note of results) {
      // console.log(note)
      groupByTick.get(note.scoreTick).push(note)
      idx = idx + 1
      if (preTick === -1) {
        preTick = note.scoreTick
        continue
      }

      if (note.scoreTick != preTick || idx == results.length) {
        const notesGroupedByTick = groupByTick.get(preTick)
        const minTime = minUserTime(notesGroupedByTick)
        if (!dedup.has(minTime)) {
          dedup.add(minTime)
          ddl.push(minTime)
        }
        this.notesSet.add(minTime, ...notesGroupedByTick)
      }
      preTick = note.scoreTick
    }

    this.playSequence = ddl
    this.currentNode = this.playSequence.head
    this.headMargin = { start: 0, end: ddl.head.element }
  }
  /**
   * Slider window by which push the score cursor forward.
   */
  private windowSlider() {
    const current = this.currentNode
    let currentUserTime
    if (current?.isNotNull()) {
      currentUserTime = current.element
    } else {
      currentUserTime = -Infinity
    }

    const next = current?.next
    let nextUserTime
    if (next?.isNotNull()) {
      nextUserTime = next.element
    } else {
      nextUserTime = Infinity
    }

    const range = {
      start: currentUserTime,
      end: nextUserTime,
      value: this.notesSet.get(currentUserTime),
      done: false
    }
    this.range = range
    this.currentNode = next
  }
  /**
   * Return the midi-event duration by audio play speed.
   */
  private getDuration(current: number, next: number): number {
    return (next - current) * this._speed
  }
  /**
   * Orgnize more detailed wrong infomation from noations.
   */
  private makeAbnormalNotes(notations: any) {
    const notes = defaultDict(Map)
    notations.forEach((item: any) => {
      if ('scoreNoteId' in item) {
        const scoreNoteId = String(item.scoreNoteId)
        const key = String(item.userTime)
        notes[key].set(scoreNoteId, item.type)
      }
    })
    return notes
  }
  /**
   * A debug helper used to print the note in result.
   */
  public static inspectNotes(...notes: any[]): void {
    for (const note of notes) {
      console.log(`${note.scoreNote?.id}-${note.scoreTick}-${note.userTime}-${note.pitch}`)
    }
    console.log('==================================================')
  }
}
