import { AudioPlayer } from '@xiaoyezi/musicscore-engine';
import BtnLock from '../../baseEngine/btnLock';
import { OceanAudioNoteAligner } from './oecanAudioNoteAlign';

const colorMap = {
  miss: 'rgb(148, 148, 148)',
  miss2: 'rgb(148, 148, 148)',
  right: 'rgb(0, 243, 111)',
  wrong: 'rgb(255, 0, 0)',
};

export default {
  data: () => ({
    speedCn: 1,
    audioPlayer: null,
    playSeekTime: 0,
    audioControler: null,
    progress: null,
    canplay: false, // 首次等待客户端加载音频
    ready: false, // 首次等musicScore待客户端加载音频
    firstLoadMidi: false,
    loadSuccess: false,
    playComplete: false,
  }),
  methods: {
    async initAudioPlay() {
      const mode =
        this.$route.query.mode in [1, 2] ? this.$route.query.mode : 1;

      this.audioPlayer = new AudioPlayer({ useWeb: true });
      this.audioPlayer.off('loadSuccess');
      this.audioPlayer.on('loadSuccess', () => {
        setTimeout(() => {
          this.loadSuccess = true;
        }, 500);
        console.log('initAudioPlay->loadSuccess');
      });

      await this.audioPlayer.load({ bgm: this.noteResultsData.audio_url });
      this.audioPlayer.off('end');
      this.audioPlayer.on('end', () => {
        console.log('listen->end');
        this.audioAligner.reset();
        this.playComplete = true;
        this.stopAudition();
      });
      try {
        this.audioAligner = new OceanAudioNoteAligner(
          this.noteResultsData,
          mode
        );
      } catch (e) {
        this.toast(e.message);
        throw e;
      }

      let results = [];
      function coincided(nextResult) {
        const nextResultLen = nextResult.length;
        const rawResultLen = results.length;
        if (nextResultLen === 0) {
          return [rawResultLen, 0];
        }

        let rawResultIdx = results.length - 1;
        let coincidedNum = 0;
        while (rawResultIdx >= 0) {
          if (results[rawResultIdx].userTime < nextResult[0].userTime) {
            break;
          }
          rawResultIdx--;
          coincidedNum = coincidedNum + 1;
        }
        // const coincidedNum = rawResultLen - rawResultIdx
        return [rawResultIdx, coincidedNum];
      }
    },

    resetNoteColor(note, color) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (note && note.scoreNote) {
        const svgNote = this.musicScore.getNoteById(note.scoreNote.id);
        if (svgNote) svgNote.color = color;
      }
    },

    // 获取试听乐句起始结束noteId
    loadAuditionMidi() {
      this.firstLoadMidi = true;
      // 提升endtick 需要修正tick，减去时值

      // TODO implement seek
      // this.audioControler.load()
      // TODO give a more accurate playSeekTime
      // this.playSeekTime = (this.audioControler.range && this.audioControler.range.start) || 0
    },

    // 试听开始播放
    startAudition() {
      if (!this.noteResultsData.audio_url) {
        console.error('没有音频文件');
        return;
      }

      if (this.listenPlaying || !this.loadFinish || !this.loadSuccess) {
        console.error('试听音频准备中，请稍候重试');
        return;
      }

      if (BtnLock.lock) {
        console.log('开始试听->操作过于频繁');
        return;
      }

      if (this.playComplete) {
        // replay, now we need clear the score.
        // ...
        this.createMusicScore();
      }
      this.playComplete = false;

      BtnLock.initLock(500);

      if (!this.firstLoadMidi) this.loadAuditionMidi();

      // 更新各种状态
      this.listenPlaying = true;
      this.cursor._followScore = true;
      this.cursor.visible = true;
      this.cursor.forceUpdate();

      this.audioPlayer.rate = this.speedCn;
      // this.audioPlayer.seek(0)

      // 播放器开始播放
      setTimeout(() => {
        this.audioPlayer.play();
      }, 200);
    },

    // 关闭试听
    stopAudition() {
      if (!this.listenPlaying) return;

      if (BtnLock.lock) {
        console.log('停止试听->操作过于频繁');
        return;
      }
      BtnLock.initLock(500);
      this.audioPlayer.pause();
      this.canplay = false;
      this.listenPlaying = false;
    },
  },
};
