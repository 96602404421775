<template>
  <div class="g-main">
    <div class="g-top">
      <div v-if="loadFinish" class="top-btn">
        <!-- 回放 -->
        <div v-if="!listenPlaying" class="btn-item" @click="startAudition()">
          <img src="/images/report/start_round.png" alt="">
        </div>
        <div v-else class="btn-item" @click="stopAudition()">
          <img src="/images/report/stop.png" alt="">
        </div>
        <!-- 释义 -->
        <div
          class="btn-item"
          @click="showScoreTips"
          :class="showTips ? 'g-index' : ''">
          <img src="/images/report/tips.png" alt="">
        </div>

      </div>
      <!-- 得分 -->
      <!-- <div class="top-score">
        得分: {{ final }}
      </div> -->
    </div>
    <!-- 曲谱 -->
    <div class="g-content" id="g-content"></div>
    <!-- 释义弹框 -->
    <div class="g-mask" :class="showTips ? 'show-mask' : ''">
      <div v-if="showTips" class="mask-tips mask-tips-big">
        <div class="tips-close" @click="closeMask"></div>
        <p class="tips-title">释义</p>
        <div class="tips-item">
          <img src="/images/report/note-green.png" alt="">
          <span>{{tipsData[0]}}</span>
        </div>
        <div class="tips-item">
          <img src="/images/report/note-red.png" alt="">
          <span>{{tipsData[1]}}</span>
        </div>

        <div class="tips-item">
          <img src="/images/report/note-red-first.png" alt="" style="width: 0.12rem">
          <span>{{tipsData[2]}}</span>
        </div>
        <div class="tips-item">
          <img src="/images/report/note-green-first.png" alt="">
          <span>{{tipsData[3]}}</span>
        </div>
        <div class="tips-item">
          <img src="/images/report/note-gray.png" alt="">
          <span>{{tipsData[4]}}</span>
        </div>
        <div class="tips-item">
          <img class="item-range" src="/images/report/range.png" alt="">
          <span>{{tipsData[5]}}</span>
        </div>
         <div class="tips-item">
          <img class="item-mark" src="/images/report/kadun_new.png" alt="">
          <span>{{tipsData[6]}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { PlayDataLoader } from '@xiaoyezi/musicscore-engine'

import { authApi } from '@/api/index.ts'
import scoreDataGetter from '@/baseEngine/scoreDataGetter'
import ReportSigner from '@/baseEngine/reportSigner'

import initMusicPlayEngineMixin from '@/mixins/initMusicPlayEngine'
import reportMixin from './report'
import audioPLayMixin from './audio_play'
import toast from '@/components/toast/index';

export default {
  mixins: [initMusicPlayEngineMixin, // music  score cavas
           reportMixin,              // render notations
           audioPLayMixin],          // replay controller

  data: () => ({
    final: 0,                       //
    showTips: false,                //
    tipsData: ['绿色音符：演奏正确', '红色音符：错音/漏音', '音符重影（红色在前）：节奏弹快了', '音符重影（红色在后）：节奏弹慢了', '灰色音符：错误过多/演奏卡顿/未演奏', '范围标注：不熟练区域', '卡顿标注：发生卡顿'],

    listenPlaying: false,           // 回放/暂停
    loadFinish: false,
    pageType: null,                 // report: 报告，rank: 排行榜，weixin：微信公众号
    reportStartNote: null,

    lessonId: '',
    scoreId: '',
    recordId: '',
    scoreData: {},                  // 曲谱资源
    scoreEditData: {},              // 曲谱配置信息
    noteResultsData: {},            // 评测结果数据
    scoreIdxMap: {},                // scoreNote与scoreNoteId映射关系
    systemIds: [],

  }),

  methods: {
    showScoreTips () {
      this.showTips = !this.showTips
    },
    closeMask () {
      this.showTips = false
    },

    initScoreData () {
      // PlayDataLoader used to genrate user time?
      this.playDataLoader = new PlayDataLoader()
      this.playDataLoader.load(this.scoreData.midi, this.scoreEditData)
    },

    initApp () {
      try {
        this.createMusicScore() // build score view
        this.initScoreData()    //
        this.initAudioPlay()}
      catch (e) {
        this.toast('曲谱资源错误')
        throw(e)
      }
      // setTimeout(() => {
      //   this.reportSigner = new ReportSigner(this.musicScore, this.noteResultsData, this.scoreData, this.playDataLoader, this.pageType)
      //   if (this.noteResultsData.version === 1) {
      //     this.reportSigner.initPlayNotation() // svg先渲染，层级高
      //     this.initNewRect() // 高亮分手分段
      //   } else {
      //     this.initRect()
      //     this.reportSigner.initPlayNotationInMusicScore(this.aiMidiLoader.noteIdMap)
      //   }
      // }, 5)
    },

    async fetchWebData () {
      console.log('fetch score data')
      try {
        const { appData, urls } = await scoreDataGetter.getAppData(this.lessonId)
        this.appData = appData
        this.urls = urls
        this.scoreData = await scoreDataGetter.getAppOperationData(this.urls.configJsonUrl, this.urls.shapeJsonUrl, this.urls.midiJsonUrl)
        this.scoreEditData = this.scoreData.config
        // now we have midi, config, svgs, pages.

      } catch (err) {
        this.toast('曲谱资源数据错误')
        console.error('Fetch Data Error: ', err)
        throw err
      }
    },

    // 获取测评记录
    async getPlayRecord (record_id) {
      console.log('fetch eval data')
      this.errorNo = null
      const data = (await authApi.getPlayRecord(record_id)).data
      // now we have user played notes list what
      // allow us to build a user scope midi for play.
      if (data.meta.code === 0) {
        this.initResultScore(data.data.score)
        this.noteResultsData = data.data
      } else {
        this.toast('AI-BACKEND数据错误')
        console.error(data.meta.msg)
      }
    },

    // 获取one文件资源
    async getOneData (url) {
      if (url) {
        url = url.replace('http://', 'https://')
        this.oneData = (await this.$ajax({
          method: 'GET',
          url: url,
          timeout: 60000
        })).data
      }
    },

    // 回放页获取声音
    async getAudio (record_id) {
      const data = (await authApi.getAudio(record_id)).data
      if (data.meta.code === 0) {
        this.noteResultsData.audio_url = data.data.audio_url
        // This is acturally the play time line motivator by which
        // we push forawrd the music playing while highlight the
        // corresponding music note.
      } else {
        this.toast('音频数据加载错误')
        console.error(data.meta.msg)
      }
    },
    initResultScore (score) {
      this.final = this.fixNum(score.simple_final.toFixed(1), parseInt(score.simple_final.toFixed(1)))
    },
    fixNum (decimal, integer) {
      if (Number(decimal) === integer) {
        return integer
      } else {
        return decimal
      }
    },
    // 读取路由参数
    readParams () {
      console.log('readParams->', this.$route.query)
      this.appToken = this.$route.query.appToken
      this.version = this.$route.query.version || '7.0.0'
      this.platform = 2
      this.lessonId = this.$route.query.lessonId
      this.scoreId = this.$route.query.scoreId
      this.recordId = this.$route.query.recordId
      this.pageType = this.$route.query.pageType || 'weixin'
      localStorage.setItem('token', this.appToken)
      localStorage.setItem('version', this.version)
      localStorage.setItem('platform', this.platform)
    }
  },

  mounted () {
    this.readParams()
    this.loadFinish = false
    // const loading = this.$loading()

    Promise.all([this.fetchWebData(), this.getPlayRecord(this.recordId)]).then(() => {
      if (this.noteResultsData.version !== 1) {
        return this.getOneData(this.urls.oneUrl)
      }
    }).then(() => {
      console.log('weixin->audioUrl:', this.noteResultsData.audio_url)
      if (!this.noteResultsData.audio_url) {
        return this.getAudio(this.recordId)
      }
    }).finally(() => {
      this.initApp()
      this.loadFinish = true
      // loading && loading.close()
    })
  }
}
</script>

<style lang="scss" scoped>
$topHeight: 0.8rem;
.g-main {
  width: 100%;
  height: 100%;
  padding-top: 0.1rem;
  .g-top {
    position: relative;
    width: 100%;
    height: $topHeight;
    background-color: #fff;
    .top-btn {
      margin-left: 0.3rem;
      height: $topHeight;
      display: flex;
      align-items: center;
      .btn-item {
        margin-right: 0.2rem;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: $topHeight * 0.6;
          height: $topHeight * 0.6;
        }
      }
    }
    .top-score {
      height: $topHeight;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.24rem;
      line-height: $topHeight;
    }
  }
  .g-content {
    position: relative;
    width: 98%;
    height: 80vh;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .g-mask {
    z-index: -1;
    opacity: 0;
    transition: all .5s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
    .mask-tips {
      position: relative;
      background-color: #fff;
      border-radius: 0.16rem;
      padding: 0.7rem 0.3rem 0.3rem 0.5rem;
      width: 5.4rem;
      height: 5.6rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: space-between;
      .tips-close {
        cursor: pointer;
        position: absolute;
        top: -0.4rem;
        right: -0.8rem;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background: url(/images/report/close.png) no-repeat;
        background-size: contain;
      }
      .tips-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 2.34rem;
        height: 0.54rem;
        margin: 0 auto;
        top: -0.107rem;
        font-size: 0.3rem;
        color: #ffffff;
        text-align: center;
        line-height: 0.54rem;
        background: url(/images/report/dialog-title.png) no-repeat;
        background-size: contain;
      }
      .tips-item {
        width: 100%;
        display: flex;
        align-items: center;
        img {
          width: 0.1rem;
          height: 0.27rem;
          margin-right: 0.4rem;
        }
        span {
          font-size: 0.16rem;
          color: #444444;
          text-align: left;
        }
        .item-range {
          width: 0.15rem;
          height: 0.21rem;
          margin-right: 0.35rem;
        }
        .item-mark {
          width: 0.27rem;
          height: 0.27rem;
          margin-left: -0.03rem;
          margin-right: 0.26rem;
        }
      }
    }
    @media screen and (min-width: 450px) {
      .mask-tips {
        width: 4.8rem;
        height: 4.5rem;
        .tips-item {
          span {
            font-size: 0.2rem;
          }
        }
      }
    }
  }
  .show-mask {
    opacity: 1;
    z-index: 3;
  }
}
.g-index {
  z-index: 4;
}
</style>
